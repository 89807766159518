import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BidStatus, BusinessUnit } from '../types';
import { formatDate } from '../utils/date.utils';
import { CoreService } from './azure/core.service';
import {
  CheckListItem,
  CoreServiceBaseRequest,
  CoreServiceWebsite,
  GetUserDetailsByBuResponse,
} from './azure/core.service.types';
import { ScheduleService } from './azure/schedule.service';
import {
  BidStatuses,
  GetDashboardInfoRequest,
  GetDashboardInfoResponse,
} from './azure/schedule.service.types';
import {
  ActionType,
  EmulationKey,
  IdbService,
  Permissions,
  Stores,
} from './idb/idb.service';
import { TokenService } from './token.service';

export enum CardTitle {
  IR = 'Input Required',
  HIC = 'HI Commands',
  WEBSITES = 'Websites',
  FN = 'By flight number',
  CKPILOT = 'Check Pilot',
  CKPILOTIR = 'Check Pilot Input Required',
}

export type CallSearchFlight = {
  arrStation: string;
  depStation: string;
  fltDepDate: string;
  fltNumber: number;
};

export type UserBidStatus = {
  contractMonth: string;
  crewBase: string;
  crewDivision: string;
  crewEquipment: string;
  crewSeat: string;
  checkAirman: boolean;
  fsmSupervisorNumber: string;
  checkAirmanType: string;
  departureStations?: string[];
  inactiveSupervisor: boolean;
};

export type CheckAirmanMenu = {
  title: string;
  isOpen: boolean;
  checkAirmanData: CheckListItem[];
};

export type MenuList = {
  title: string;
  order: number;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private coreService: CoreService,
    private tokenService: TokenService,
    private scheduleService: ScheduleService,
    private pingAuthService: PingAuthenticationService,
    private idbService: IdbService,
    private router: Router,
  ) {
  }

  isLoading = signal(true);

  airlineCode: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.Organization ?? '',
  );

  firstName: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.first_name ?? '',
  );

  lastName: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.last_name ?? '',
  );

  employeeNumber: Signal<number> = computed(() =>
    Number.parseInt(this.tokenService.tokens()?.accessToken.uid ?? ''),
  );

  employeeBusinessUnit: WritableSignal<BusinessUnit | undefined> =
    signal(undefined);
  isEmulationEnabled: Signal<boolean> = computed(
    () => !!this.emulatedBusinessUnit(),
  );

  isLoggedIn: Signal<boolean> = computed(
    () => !!this.tokenService.tokens()?.accessToken,
  );

  dashBoardInfoResponse: WritableSignal<GetDashboardInfoResponse | undefined> =
    signal(undefined);

  apiDetails: WritableSignal<
    | {
        businessUnit: BusinessUnit;
        isAdmin: boolean;
        isActive: boolean;
        emulatedFirstName: string;
        emulatedLastName: string;
        websites: CoreServiceWebsite[];
        mailBox: string;
      }
    | undefined
  > = signal(undefined);

  emulatedEmployeeNumber: WritableSignal<number | undefined> =
    signal(undefined);

  emulatedBusinessUnit: WritableSignal<BusinessUnit | undefined> =
    signal(undefined);

  emulatedAirlineCode: WritableSignal<string | undefined> = signal(undefined);

  isEmployeeCheckAirman: WritableSignal<boolean> = signal(false);

  isAAFAMQFA = computed(() => {
    return (
      this.apiDetails()?.businessUnit === BusinessUnit.AAFA ||
      this.apiDetails()?.businessUnit === BusinessUnit.MQFA
    );
  });

  isAAPIMQPI = computed(() => {
    return (
      this.apiDetails()?.businessUnit === BusinessUnit.AAPI ||
      this.apiDetails()?.businessUnit === BusinessUnit.MQPI
    );
  });

  paddedEmployeeNumber = computed(() => {
    let employeeNumber = this.employeeNumber().toString();
    if (employeeNumber && employeeNumber.length !== 6) {
      employeeNumber = employeeNumber.toString().padStart(6, '0');
    }
    return employeeNumber;
  });

  emulatedOrDefaultEmployeeNumber = computed(
    () => this.emulatedEmployeeNumber() ?? this.employeeNumber(),
  );

  emulatedOrDefaultBusinessUnit = computed(() =>
    this.isEmulationEnabled()
      ? this.emulatedBusinessUnit()
      : this.apiDetails()?.businessUnit,
  );

  emulatedOrDefaultAirlineCode = computed(
    () => this.emulatedAirlineCode() ?? this.airlineCode(),
  );

  // {versionNumber}-{empId}-{emulatedEmpId}-{useragent}
  appSession = computed(
    () =>
      `${environment.version}-${this.employeeNumber()}-${
        this.emulatedEmployeeNumber() ?? 'NotEmulating'
      }-${navigator.userAgent}`,
  );

  /**
   * Set four part Bit status
   * It is used in search, overview, message pages
   * @returns
   */
  async generateDashboardInfoRequest(
    employeeLogin: number,
    airlineCode: string,
    businessUnit: BusinessUnit,
  ): Promise<void> {
    const bidStatusPayload: GetDashboardInfoRequest = {
      localDate: formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      siteMinderEmployeeId: this.employeeNumber(),
      employeeLogin,
      airlineCode,
      businessUnit,
      appSessionId: this.appSession(),
    };
    await this.getDashboardInfoResponse(bidStatusPayload);
  }

  /**
   * Dashboard api call
   * @param payload
   * @returns
   */
  async getDashboardInfoResponse(
    payload: GetDashboardInfoRequest,
  ): Promise<GetDashboardInfoResponse> {
    const dashboardResponse = await firstValueFrom(
      this.scheduleService.getDashBoardInfo(payload),
    );
    if (dashboardResponse.success && dashboardResponse.dashBoardResponse) {
      this.setDashboardInfo(dashboardResponse);
      this.validateCheckAirman(dashboardResponse);
    } else {
      this.setDashboardInfo({} as GetDashboardInfoResponse);
    }
    return dashboardResponse;
  }

  /**
   * Validate the employee is a checkairman.
   * In the search page we are displaying extra menu list for checkairman.
   * Checkairman is validated based on the Contract month Bid status value.
   * @param dashboardResponse
   */
  validateCheckAirman(dashboardResponse: GetDashboardInfoResponse): void {
    const bidStatus = dashboardResponse?.dashBoardResponse?.bidStatuses?.find(
      (bitStatus: BidStatuses) =>
        bitStatus.contractMonthType === BidStatus.CONTRACT,
    );
    if (bidStatus) {
      this.isEmployeeCheckAirman.set(
        bidStatus.inactiveSupervisor || bidStatus.checkAirman,
      );
    }
  }

  /**
   * On emulate get the emulated user details
   * @returns
   */
  async setApiDetails(isEmulated = false): Promise<void> {
    const payload: CoreServiceBaseRequest = {
      airlineCode: this.airlineCode(),
      appSessionId: this.appSession(),
      empIdLogin: this.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.employeeNumber(),
    };
    const userDetailsResult = await firstValueFrom(
      this.coreService.getUserDetailsByBu(payload),
    );
    if (!userDetailsResult || !userDetailsResult.success) {
      this.isLoading.set(false);
      console.error('Failed to load user details', userDetailsResult);
      return;
    } else {
      this.emulatedAirlineCode.set(userDetailsResult.businessUnit.airlineCode);
      this.apiDetails.set({
        businessUnit: userDetailsResult.businessUnit.bu as BusinessUnit,
        isActive: userDetailsResult.businessUnit.active,
        isAdmin: userDetailsResult.businessUnit.admin,
        emulatedFirstName: userDetailsResult.firstName,
        emulatedLastName: userDetailsResult.lastName,
        websites: userDetailsResult.websites,
        mailBox: userDetailsResult.businessUnit.mailBox,
      });

      if (isEmulated) {
        await this.storeEmulatedData(userDetailsResult);
      } else {
        this.employeeBusinessUnit.set(
          userDetailsResult.businessUnit.bu as BusinessUnit,
        );
      }
      const employeeLogin = this.emulatedOrDefaultEmployeeNumber();
      const airlineCode = this.emulatedOrDefaultAirlineCode();
      const businessUnit = this.emulatedOrDefaultBusinessUnit()!;
      await this.generateDashboardInfoRequest(
        employeeLogin,
        airlineCode,
        businessUnit,
      );
    }
  }

  async storeEmulatedData(
    buDetails: GetUserDetailsByBuResponse,
  ): Promise<void> {
    const emulationState: EmulationState = {
      employeeId: buDetails.employeeID ?? '',
      businessUnit: buDetails.businessUnit.bu ?? '',
      emulatedFirstName: buDetails.firstName ?? '',
      emulatedLastName: buDetails.lastName ?? '',
      websites: buDetails?.websites ?? ([] as CoreServiceWebsite[]),
    };
    await this.emulationState(ActionType.Put, emulationState);
  }

  /**
   * load is called in app.component.ts
   * (!isEmulated && emulateData) - If the emulated data is stored in index db, on load application get the emulated data call.
   * @param isEmulated
   * @returns
   */
  async load(isEmulated = false): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        this.isLoading.set(true);
        const loggedIn = await firstValueFrom(this.pingAuthService.loggedIn$);
        if (!loggedIn) {
          this.isLoading.set(false);
          reject();
          return;
        }
        this.tokenService.load();
        const emulateData = await this.emulationState(ActionType.Get);
        if (!isEmulated && emulateData) {
          await this.getEmulatedStateData(emulateData);
        } else {
          await this.setApiDetails(isEmulated);
        }
        this.isLoading.set(false);
        resolve();
      } catch (error) {
        this.isLoading.set(false);
        reject(error);
      }
    });
  }

  async clearEmulation(): Promise<void> {
    this.emulatedBusinessUnit.set(undefined);
    this.emulatedEmployeeNumber.set(undefined);
    await this.emulationState(ActionType.Delete);
    await this.load();
  }

  /**
   * This method is called from settings-modal.component.ts
   * @param employeeId
   * @param businessUnit
   */
  async emulate(employeeId: number, businessUnit: BusinessUnit): Promise<void> {
    this.emulatedBusinessUnit.set(businessUnit);
    this.emulatedEmployeeNumber.set(employeeId);
    await this.load(true);
  }

  /**
   * Update/Delete/Get - Emulation data in the store
   * @param action - Delete/Get/Put
   * @param emulationData - {} as EmulationState
   */
  async emulationState(
    action: ActionType,
    emulationData?: EmulationState,
  ): Promise<void | EmulationState> {
    try {
      const db = await this.idbService.dbPromise;
      const transaction = db.transaction(Stores.Emulation, Permissions.Write);
      const store = transaction.objectStore(Stores.Emulation);

      switch (action) {
        case ActionType.Put:
          await store.put(emulationData, EmulationKey);
          return;
        case ActionType.Delete:
          await store.delete(EmulationKey);
          return;
        case ActionType.Get:
          return await store.get(EmulationKey);
      }
    } catch (error) {
      console.error('Emulation error');
      console.error(error);
    }
  }

  async getEmulatedStateData(emulatedData: EmulationState): Promise<void> {
    this.apiDetails.set({
      businessUnit: emulatedData.businessUnit as BusinessUnit,
      isAdmin: false,
      isActive: false,
      emulatedFirstName: emulatedData.emulatedFirstName ?? '',
      emulatedLastName: emulatedData.emulatedLastName ?? '',
      websites: emulatedData.websites as CoreServiceWebsite[],
      mailBox: '',
    });
    this.emulatedBusinessUnit.set(emulatedData.businessUnit as BusinessUnit);
    this.emulatedEmployeeNumber.set(Number(emulatedData.employeeId));
    await this.setApiDetails(true);
  }

  logout(): void {
    this.clearEmulation();
    this.tokenService.logout();
  }

  /**
   * Set dashboard info value
   * @param response
   */
  setDashboardInfo(response: GetDashboardInfoResponse): void {
    response.responseTime = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss');
    response.pageUrl = this.router.url;
    this.dashBoardInfoResponse.set(response);
  }

  /**
   * Get dashboard info value
   * @returns
   */
  getDashboardInfo(): GetDashboardInfoResponse | undefined {
    return this.dashBoardInfoResponse();
  }
}

type EmulationState = {
  employeeId: number;
  businessUnit: BusinessUnit;
  emulatedFirstName: string;
  emulatedLastName: string;
  websites: CoreServiceWebsite[];
};
