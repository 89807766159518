import { BusinessUnit } from '../../types';

export type GetBidStatusRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit | undefined;
  employeeLogin: number | undefined;
  localDate: string;
  siteMinderEmployeeId: number;
  uniqueId?: string;
};

export type GetBidStatusResponse = {
  airlineCode: string;
  errorMessage: null | string;
  businessUnit: BusinessUnit | undefined;
  employeeLogin: number | undefined;
  bidStatusInfo: BidStatusInfo;
};

export type BidStatusInfo = {
  currentMonthBid: UserBidStatus;
  departureStations: [];
};
export type UserBidStatus = {
  contractMonth: string;
  currentBase: string;
  currentDivision: string;
  currentEquipment: string;
  seatCategory: string;
  checkAirman: boolean;
  fsmSupervisorNumber: string;
  checkAirmanType: string;
};

export type MessageBidStatus = {
  contractMonth: string;
  crewBase: string;
  crewDivision: string;
  crewEquipment: string;
  crewSeat: string;
  checkAirman: boolean;
  fsmSupervisorNumber: string;
  checkAirmanType: string;
  departureStations: [];
};

export type CoreServiceBaseResponse = {
  businessUnit: BusinessUnit;
  empIdLogin: number;
  errorInfo: {
    businessContext: string;
    errorCode: string;
    errorMessage: string;
    errorReference: string;
    httpStatusCode: string;
    severity: string;
    success: boolean;
    validationFailureMessages: string[];
  };

  errorMessage: string[];
  messageAction: string;
  success: boolean;
};

export type GetCCIMsgDetailByMsgIdRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken?: string;
  empIdLogin: number;
  msgId: number;
  siteMinderEmpId: number;
  uniqueValue?: string;
};

export type GetCCIMsgDetailByMsgIdResponse = CoreServiceBaseResponse & {
  acknowledgeRequired: boolean;
  alertMsgPosition: string;
  deletedBy: number;
  deletedCalendar: string;
  deleteIndicator: number;
  effectiveCalendar: string;
  endCalendar: string;
  insertedBy: number;
  insertedCalendar: string;
  msgBody: string;
  msgId: number;
  msgTab: string;
  msgTitle: string;
  priorityNumber: number;
  updatedBy: number;
  updatedCalendar: string;
};

export type GetEmployeeMessagesRequest = {
  airlineCode: string;
  businessUnit?: BusinessUnit;
  empIdLogin?: number; // ! EMULATED USER ID
  appSessionId: string;
  siteMinderEmpId: number;
  employeeMessageRequest: GetEmployeeRequestEmployeeMessage;
};

export type GetEmployeeRequestEmployeeMessage = {
  airlineCode: string;
  businessUnit?: BusinessUnit;
  empIdLogin?: number; // ! EMULATED USER ID
  appSessionId: string;
  siteMinderEmpId: number;
  admin: boolean;
  deviceToken?: string;
  uniqueValue?: string;
  contractMonth: string;
  crewBase: string;
  crewDivision: string;
  crewEquipment: string;
  crewSeat: string;
  checkAirman: boolean;
  fsmSupervisorNumber: string;
  mailBox?: string;
  checkAirmanType: string | null;
  departureStations?: string[];
};

// * This type is only used in the application and not directly from the API
export type FullMessageData = MessageData & { body?: string | null } & {
  isHi6Message?: boolean;
};

export type MessageData = {
  acknowledged: boolean;
  acknowledgeRequired: number;
  delEmpId: number;
  effectiveDate: string;
  empId: number;
  insEmpId: number;
  messageId: number;
  messageRead: number; // ! this is really a boolean value but api side is botched.
  messageReadUnique: number;
  messageTab: string;
  messageTitle: string;
  priorityNumber: MessagePriority;
  siteMinderEmpId: number;
  updEmpId: number;
  emulated: boolean;
  messageDeleted: boolean;
  messageExpired: boolean;
  messageSignature: string;
  acknowledgementRequired: boolean;
  isMessageSaved?: boolean;
};

export enum MessagePriority {
  Low = 1,
  Medium = 2,
  ReadAndAcknowledge = 3,
  // ! There has been a misunderstanding.
  // ! Values below are not provided by the API.
  // ! However they are still used in this app for menu navigation / etc.
  // ! If you remove these be very careful.
  // ! Sorry for the muckery.
  High = -1,
  Deleted = -2,
  Saved = -3,
  // ! to fetch Hi6 messages we need to call getSabreCommands api
  HI6 = -4,
}

export type GetEmployeeMessagesResponse = {
  empMessages: {
    empIdLogin: number;
    success: boolean;
    totalMessageCount: number;
    unreadMessageCount: number;
    unreadReadandSignCount: number;
    messageDataArray: MessageData[];
  };
};

export type ManageEmployeeMessageRequest = {
  actionType: string;
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  empIdLogin: number;
  employeeMsgList: {
    msgId: number;
    priorityNumber: number;
  }[];
  siteMinderEmpId: number;
  uniqueValue: string;
};

export type ManageEmployeeMessageResponse = CoreServiceBaseResponse & {
  employeeMsgList: {
    messageId: number;
    priorityNumber: number;
  }[];
  failureMessage: string;
};

export type AcknowledgeCCIMessageRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  empIdLogin: number;
  msgId: number;
  siteMinderEmpId: number;
  uniqueValue: string;
};

export type AcknowledgeCCIMessageResponse = {
  businessUnit: unknown;
  empIdLogin: number;
  errorMessage: unknown;
  messageAction: unknown;
  success: boolean;
  errorInfo: unknown;
};
